<template>
  <section v-if="!loading" class="border custom-border">
    <v-sheet class="border">
      <Header 
        @createPost="dialog=true" 
        :courses = "announcement_courses" 
        @setFilter="setFilter" 
        @setDateInterval="setDateInterval" 
        v-if="!selected_announcement_id"/>
      <div v-else class="d-flex align-center pa-5">
        <v-btn 
          icon 
          @click="selected_announcement_id=null, this.selected_date_interval = interval, this.selected_course = course" 
          class="mr-2">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div class="roboto f16 fw600 secondary-1--text my-2">Announcements</div>
      </div>
      
      <v-divider></v-divider>
      
      <v-sheet v-if="filteredAnnouncements().length==0" class="border d-flex flex-column align-center justify-center pb-10 text-center" height="60vh">
        <div class="">
          <v-img 
            max-width="300" 
            contain
            :src="require('../../assets/default/empty_drafts.png')" />
        </div>
        <h3 class="poppins secondary-1--text fw600 my-5 text-center">You haven’t posted any announcements yet.</h3>
        <!-- <a 
          href="#" 
          @click="dialog=true" 
          class="text-decoration-underline f14 poppins primary--text"
        >
          Post Announcement
        </a> -->
      
      </v-sheet>
      <v-container v-else min-height="60vh" max-height="60vh" color="grey lighten-5" class="border-bottom overflow-y-auto mb-5">
        <PostCard 
          v-for="item in filteredAnnouncements()" 
          :id="`comment-${item.id}`"
          :key="item.id" 
          :item="item"
          :user="user"
          @remove="remove"
          :selected_announcement_id="selected_announcement_id"
          :selected_announcement_comment_id="selected_announcement_comment_id"
          @setAnnouncement="setAnnouncement"
          @updateAnnouncement="updateAnnouncement"
        />
      </v-container>
      
    </v-sheet>

    <PostDialog 
      :dialog="dialog" 
      :user="user"
      :courses="courses"
      @close="dialog=false"
      @post="posted"
    />
    <PostUpdateDialog
      :dialog="editDialog" 
      :user="user"
      :courses="courses"
      :announcement="selected_announcement"
      @close="editDialog=false"
    />

    <!-- <toaster :show="saved" @close="saved=false" :text="text"/> -->
  
    <delete-toast 
      :dialog="deleteDialog"
      type="Announcement"
      @delete="trash"
      @cancel="deleteDialog=false"
    />
  
  </section>
  <circular v-else/>

</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import Toaster from '../../components/reusable/Toaster.vue'
import Header from '../../components/teacher/announcement/Header.vue'
import PostCard from '../../components/teacher/announcement/PostCard.vue'
import PostDialog from '../../components/teacher/announcement/PostDialog.vue'
import PostUpdateDialog from '../../components/teacher/announcement/PostUpdateDialog.vue'

export default {
  components: {
    Header,
    PostCard,
    PostDialog,
    Toaster,
    PostUpdateDialog
  },
  computed: {
   ...mapState({
     user: (state) => state.user
   }),
   ...mapState('instructor', {
    courses: (state) => state.courses,
    announcements: (state) => {
      return state.announcements
    },
    announcement_courses: (state) => {
      let titles = []
      state.announcements.forEach(item =>
        {
          if(item.course !== null){
            titles.push(item.course.title)
          }
        } 
      )
      return Array.from(new Set(titles))
    }
   }),
  },
  data: () => ({
    post: 'Write Something...',
    dialog: false,
    deleteDialog: false,
    editDialog: false,
    saved: false,
    text: '',
    loading: true,
    idToDelete: null,
    selected_announcement: null,
    selected_announcement_id: null,
    selected_announcement_comment_id: null,
    selected_course: '',
    selected_date_interval: '',
    endpoint: '/instructor/announcements',
  }),

  mounted() {
    if(this.$route.query.course) {
      this.selected_course = this.$route.query.course
    } 
    if (this.$route.query.interval) {
      this.selected_date_interval = this.$route.query.interval
    }
    if(!this.$route.query.course && !this.$route.query.interval) {
      this.$router.replace({ query: { course: this.selected_course, interval: this.selected_date_interval, timestamp: Date.now() } });
    }

    this.getInstructorAnnouncementsAction().then(res=>{
      this.loading=false
    })
    
    if(this.$route.params.announcement_id && this.$route.params.announcement_comment_id) {
      this.selected_announcement_id = this.$route.params.announcement_id
      this.selected_announcement_comment_id =  this.$route.params.announcement_comment_id
      
      // this.$vuetify.goTo("#announcement-container" , { container: `#comment-${this.selected_announcement_comment_id}` })
    }
  },

  created() {
    this.getInstructorAnnouncementsAction().then(res=>{
      this.loading=false
    })
    
    if(this.$route.params.announcement_id && this.$route.params.announcement_comment_id) {
      this.selected_announcement_id = this.$route.params.announcement_id
      this.selected_announcement_comment_id =  this.$route.params.announcement_comment_id
      setTimeout(() => {
        this.$vuetify.goTo(`#comment-${this.selected_announcement_comment_id}`, { offset: 50})
      }, 1000)
    }
  },

  methods: {
    ...mapActions('instructor', ['getInstructorAnnouncementsAction']),

    async posted(e) {
      this.dialog=false
      this.text = e
      this.saved =true
      this.getInstructorAnnouncementsAction().then(res=>{
        this.loading=false
      })
    },

    updateAnnouncement(e) {
      this.selected_announcement = e
      this.editDialog = true
    },

    remove(event) {
      this.deleteDialog=true
      this.idToDelete=event
    },

    async trash() {
      await this.$api.delete(`${this.endpoint}/${this.idToDelete}`)
      .then(res => {

        this.deleteDialog=false
        this.getInstructorAnnouncementsAction().then(res=>{
          this.loading=false
        })
        this.$store.commit('alertMutation', {
          show: true,
          text: res.data.message,
          type: "success"
        })
      })
    },

    filteredAnnouncements(){ 
      let _announcement = this.announcements
      if(this.selected_announcement_id) {
        return _announcement.filter(i => i.id == this.selected_announcement_id)
      } else {
        if(this.selected_date_interval == 'Today') {
          _announcement = _announcement.filter(i => 
            this.$dateFormat.mmDDyy(i.created_at) == this.$dateFormat.mmDDyy(new Date())
          )
        } else if (this.selected_date_interval.includes('Go to')) {
          _announcement = _announcement.filter(i => {
            return moment(this.selected_date_interval.split(' ')[2]).isSame(moment(i.created_at), 'year') && moment(this.selected_date_interval.split(' ')[2]).isSame(moment(i.created_at), 'month')
          })


        } else {
          var type = ''
          if(this.selected_date_interval == 'This week') type = 'week'
          if(this.selected_date_interval == 'This month') type = 'month'
          if(this.selected_date_interval == 'This year') type = 'year'

          _announcement = _announcement.filter(i => {
            let item_created_at = moment(i.created_at)
            let from_date = moment().startOf(type)
            let to_date = moment().endOf(type)

            return from_date <= item_created_at && to_date >= item_created_at
          })

        }
      }
      
      return this.selected_course !== '' ? _announcement.filter(i => i.course && i.course.title == this.selected_course).sort(function(a, b) {
        return new Date(b.created_at) - new Date(a.created_at)
      }) : _announcement.sort(function(a, b) {
        return new Date(b.created_at) - new Date(a.created_at)
      })
    },

    setFilter(course){
      this.$router.replace({ query: { ...this.$route.query, course: course, timestamp: Date.now() } });
      this.selected_course = course
    },

    setDateInterval(interval){
      this.$router.replace({ query: { ...this.$route.query, interval: interval, timestamp: Date.now() } });
      this.selected_date_interval = interval
    },

    setAnnouncement(id) {
      this.selected_announcement_id = id
    }
  }
}
</script>