<template>
  <v-dialog v-model="dialog" max-width="550" persistent eager retain-focus="false">
    <v-card>
      <v-form
        ref="form"
        v-model="valid">
        <v-card-title class="d-flex align-center justify-space-between">
          <h4 class="primary--text">Post Announcement</h4>
          <div class="">
            <v-btn icon @click="()=>{
              this.$refs.form.reset()
              $emit('close')
            }">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <label class="caption">COURSE *</label>
          <v-select   
            :items="courses"
            item-value="id"
            item-text="title"    
            outlined
            dense
            class="general-custom-field roboto f14 secondary-1--text fw500 mb-5"
            v-model="course_id"
            required
            :rules="rules"
            hide-details="auto"
          >
          </v-select>
          <FormTextAreaRequired 
            :label="'CONTENT *'"
            :value.sync="content"
            :error="errors.content"
          /> 
          <!-- <label class="poppins f12 secondary--text mx-1">CONTENT *</label>
          <editor
            v-if="showMce"
            class="tiny"
            :api-key="mce_key"
            :rules="rules"
            v-model="content"
            :init="{
              height: 200,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }"
          />
          <circular v-else />
          <v-alert
            :value="alert"
            type="error"
            transition="v-scroll-y-transition"
            dense
            outlined
            class="mt-3 f13"
          >
            Announcement content is required
          </v-alert> -->
          <div class="d-flex align-center">
            <v-switch inset dense v-model="allow_comments"/>
            <div class="roboto">Enable comments</div>
          </div>

        </v-card-text>
        <v-divider class=""/>
        <v-card-actions class="d-flex align-center justify-end">
          <v-btn class="text-capitalize poppins secondary-1--text" text @click="()=>{
            this.$refs.form.reset()
            $emit('close')
          }">Cancel</v-btn>
          <v-btn class="text-capitalize poppins" color="primary" width="100" @click="post" :loading="loading">Post</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
// import Editor from "@tinymce/tinymce-vue";

export default {
  props:['dialog', 'user', 'courses'],
  // components: {
  //   editor: Editor,
  // },
  data: () => ({
    valid: true,
    rules: [ v => !!v || 'This is required' ],
    course_id: '',
    content: '',
    allow_comments: false,
    errors: {},
    loading: false,
    component_key: 1,
    alert: false,
  }),

  watch: {
    dialog(val) {
      if(val) {
        this.errors = {}
        this.course_id = ''
        this.content = ''
        // this.showMce = false;
        // this.content = ''
        // this.$nextTick(() => {
        //   setTimeout(() => {
        //     this.showMce = true;
        //   }, 2000);
        // });
      }
    }
  },

  beforeMount(){
  },

  methods: {
    async post() {
      if(this.$refs.form.validate()){
        // if(this.content.trim() === '') {
        //   this.alert = true
        //   return 
        // }
        this.loading = true
        await this.$api.post(`/instructor/announcements`, {
          user_id: this.user.id,
          course_id: this.course_id,
          content: this.content,
          allow_comments: this.allow_comments
        })
        .then(res => {
          this.course_id = []
          this.allow_comments = false
          this.content = ''
          this.$emit('post', res.data.message)
          this.loading = false
        })
        .catch(errs => {
          this.errors = errs.response.data.errors
          this.loading = false
        })
      }
    }
  }
}
</script>