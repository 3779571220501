var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"550","persistent":"","eager":"","retain-focus":"false"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between"},[_c('h4',{staticClass:"primary--text"},[_vm._v("Post Announcement")]),_c('div',{},[_c('v-btn',{attrs:{"icon":""},on:{"click":()=>{
            this.$refs.form.reset()
            _vm.$emit('close')
          }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-card-text',[_c('label',{staticClass:"caption"},[_vm._v("COURSE *")]),_c('v-select',{staticClass:"general-custom-field roboto f14 secondary-1--text fw500 mb-5",attrs:{"items":_vm.courses,"item-value":"id","item-text":"title","outlined":"","dense":"","required":"","rules":_vm.rules,"hide-details":"auto"},model:{value:(_vm.course_id),callback:function ($$v) {_vm.course_id=$$v},expression:"course_id"}}),_c('FormTextAreaRequired',{attrs:{"label":'CONTENT *',"value":_vm.content,"error":_vm.errors.content},on:{"update:value":function($event){_vm.content=$event}}}),_c('div',{staticClass:"d-flex align-center"},[_c('v-switch',{attrs:{"inset":"","dense":""},model:{value:(_vm.allow_comments),callback:function ($$v) {_vm.allow_comments=$$v},expression:"allow_comments"}}),_c('div',{staticClass:"roboto"},[_vm._v("Enable comments")])],1)],1),_c('v-divider',{}),_c('v-card-actions',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"text-capitalize poppins secondary-1--text",attrs:{"text":""},on:{"click":()=>{
          this.$refs.form.reset()
          _vm.$emit('close')
        }}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"text-capitalize poppins",attrs:{"color":"primary","width":"100","loading":_vm.loading},on:{"click":_vm.post}},[_vm._v("Post")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }